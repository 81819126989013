<template>
  <checkout-cart-layout @click.native="closeCalendar">
    <div class="shopping-cart">
      <div class="shopping-cart__content">
        <h1 v-show="quote && quote.totalAmount > 0 && ( quote.visit.departureDate )">{{ $t('shoppingCart') }}</h1>
        <div v-if="!shoppingCartActive" class="shopping-cart__empty">
          <img src="@/assets/empty-cart.svg" alt="">
          <button @click="openDrawer">{{ $t('getPrices') }}</button>
        </div>
        <div v-else-if="quote" class="shopping-cart__card" :class="{disabled: inRemoteActivity}">
          <div class="shopping-cart__card__dates" :class="{'unavailable': (isUnavailableCabins || isUnavailableTipis || isUnavailableLakeVillages)}">
            <div class="date-picker" >
              <h3>{{ $t('dates') }}</h3>
              <div class="dates" id="datePickerDates">
                <img v-if="quote.visit.arrivalTime" class="arrival-time" :src="require('@/assets/'+quote.visit.arrivalTime.toLowerCase()+'.svg')" alt="">
                <span class="mobile">{{ mediumDate(quote.visit.arrivalDate) }}</span>
                <span class="desktop">{{ largeDate(quote.visit.arrivalDate) }}</span>
                <img class="arrow" src="@/assets/products/arrow-right.svg" alt="">
                <span class="mobile">{{ mediumDate(quote.visit.departureDate) }}</span>
                <span class="desktop">{{ largeDate(quote.visit.departureDate) }}</span>
              </div>
            </div>
            <a id="shopping-cart-edit-quote" class="shopping-cart__card__dates__edit-visit" href="" @click.prevent="displayDrawer()">
              <img src="@/assets/edit.svg" alt="">{{$t('editVisit')}}
            </a>
            <div class="shopping-cart__card__arrival-time" v-if="hasPasses">
              <img v-if="quote.visit.arrivalTime" :src="require('@/assets/'+quote.visit.arrivalTime.toLowerCase()+'--gray.svg')" alt="">
              <span>{{ $t(quote.visit.arrivalTime === 'NIGHT' ? 'between6pm12am' : 'between7pm6pm') }}</span>
            </div>
            <div class="shopping-cart__card__arrival-time" v-else>
              <span class="notice">{{ arrivalTimeNotice }}</span>
            </div>
          </div>
          <div class="shopping-cart__card__items" v-for="(item, index) in items" :key="index">
            <div class="shopping-cart__card__item">
              <div class="item__header">
                <div class="item__header__name">
                  <img :src="require('@/assets/services/'+item.icon+'.svg')" alt="">
                  <span>{{$t(item.name)}}</span>
                </div>
                <div class="add-more-counter">
                  <label>{{item.quantity}}</label>
                </div>
                <div class="choose" v-if="item.name === 'teepee' && item.quantity > 0">
                  <span>{{tipis}}</span>
                  <a href="" id="shopping-cart-pick-your-tipi" @click.prevent="openChooseTipiModal">{{$t('modal.chooseReservation.change')}}</a>
                </div>
                <div class="choose" v-if="item.name === 'cabins' && item.quantity > 0">
                  <span>{{cabins}}</span>
                  <a href="" id="shopping-cart-pick-your-cabin" @click.prevent="openChooseCabinModal">{{$t('modal.chooseReservation.change')}}</a>
                </div>
                <div class="choose" v-if="item.name === 'lakeVillage' && item.quantity > 0">
                  <span>{{lakeVillages}}</span>
                  <a href="" id="shopping-cart-pick-your-village" @click.prevent="openChooseLakeVillageModal">{{$t('modal.chooseReservation.change')}}</a>
                </div>
              </div>
              <div class="item__amount">
                <div>{{ $t('unitPrice') }}</div>
                <div>{{item.price | currency}}</div>
              </div>
              <div class="item__breakdown">
                <span>{{item.quantity}} x {{item.price | currency}}</span>
              </div>
              <div class="item__total-amount">
                <div>Total</div>
                <div>{{(item.quantity * item.price) | currency}}</div>
              </div>
              <div class="item__options" v-if="canDelete(item)">
                <button class="delete" @click="validateDeleteItem(item)">{{$t('remove')}}</button>
              </div>
              <div v-else class="item__options" :class="{'removes': canDelete }">
                <span class="delete disabled" v-tooltip.ios.top="tooltipOptions">{{$t('remove')}}</span>
              </div>
            </div>
          </div>
          <div class="shopping-cart__card__table">
            <div class="shopping-cart__card__table__header__row">
              <div>{{ $t('quantity') }}</div>
              <div>{{ $t('products') }}</div>
              <div>{{ $t('unitPrice') }}</div>
              <div>Total</div>
            </div>
            <div class="shopping-cart__card__table__body__row" v-for="item in items" :key="item.id"
              :class="{cabin: item.name === 'cabins' && item.quantity > 0, tipi: item.name === 'teepee' && item.quantity > 0, 'lake-village': item.name === 'lakeVillage' && item.quantity > 0}">
              <div class="add-more-div quantity">
                <div class="add-more-counter">
                  <label>{{item.quantity}}</label>
                </div>
                <button v-if="canDelete(item)" class="delete" @click="validateDeleteItem(item)">{{$t('remove')}}</button>
                <span v-else class="delete disabled" v-tooltip.top="tooltipOptions">{{$t('remove')}}</span>
              </div>
              <div class="description">
                <img :src="require('@/assets/services/'+item.icon+'.svg')" alt="">
                <span>{{$t(item.name)}}</span>
                <span class="hours" v-if="item.hours">{{ item.hours }} {{ item.hours != 1 ? $t('hours') : $t('hour') }} </span>
              </div>
              <div class="price">{{item.price | currency}}</div>
              <div class="total">{{(item.quantity * item.price * ( item.hours && item.hours > 0 ? item.hours : 1 )) | currency}}</div>
              <div class="choose" v-if="item.name === 'teepees' && item.quantity > 0">
                <span>{{tipis}}</span>
                <a id="shopping-cart-pick-your-tipi" @click.prevent="openChooseTipiModal">{{$t('modal.chooseReservation.change')}}</a>
              </div>
              <div class="choose" v-if="item.name === 'ecotouristCabins' && item.quantity > 0">
                <span>{{cabins}}</span>
                <a id="shopping-cart-pick-your-cabin" @click.prevent="openChooseCabinModal">{{$t('modal.chooseReservation.change')}}</a>
              </div>
              <div class="choose" v-if="item.name === 'lakeVillages' && item.quantity > 0">
                <span>{{lakeVillages}}</span>
                <a id="shopping-cart-pick-your-village" @click.prevent="openChooseLakeVillageModal">{{$t('modal.chooseReservation.change')}}</a>
              </div>
            </div>
          </div>
          <div class="shopping-cart__unavaible" v-if="unavailableCabins || unavailableTipis || unavailableLakeVillages || yellowDateSelected || isLodge || isCapacityFull" >
              <div v-if="unavailableCabins" class="shopping-cart__card__dates__unavailable__product">
                  <img src="@/assets/date-picker/unavailable.svg" alt="">
                  <span>{{$t('noCabins')}}</span>
              </div>
              <div v-if="unavailableTipis" class="shopping-cart__card__dates__unavailable__product">
                  <img src="@/assets/date-picker/unavailable.svg" alt="">
                  <span>{{$t('noTipis')}}</span>
              </div>
              <div v-if="unavailableLakeVillages" class="shopping-cart__card__dates__unavailable__product">
                  <img src="@/assets/date-picker/unavailable.svg" alt="">
                  <span>{{$t('noLakeVillages')}}</span>
              </div>
              <div v-if="isLodge" class="shopping-cart__card__dates__camping-pass">
                  <img src="@/assets/important-24.svg" alt="">
                  <span>{{campingPassNotice}}</span>
              </div>
              <div v-if="yellowDateSelected" class="shopping-cart__card__dates__unavailable__product">
                  <img src="@/assets/date-picker/unavailable.svg" alt="">
                  <span>{{$t('advertYellowDates')}}</span>
              </div>
              <div v-if="isVillages" class="shopping-cart__card__dates__camping-pass">
                <img src="@/assets/important-24.svg" alt="">
                <span>{{ $t('additionLakeVillas') }}</span>
              </div>
              <div class="shopping-cart__card__dates__unavailable__product" v-if="isCapacityFull">
                <img src="@/assets/info.svg" alt="">
                <span>{{$t('capacityFull')}}</span>
            </div>
          </div>
          <div v-show="needMoreCabins" class="shopping-cart__card__table__need-more-cabins">
            <img src="@/assets/date-picker/unavailable.svg" alt="">
            <span>{{$t('maxCabins')}}</span>
            <a href="" class="shopping-cart__card__table__need-more-cabins__add" @click.prevent="addCabin">{{$t('addMoreCabin')}}</a>
          </div>
          <div v-show="needMoreVillages" class="shopping-cart__card__table__need-more-villages">
            <img src="@/assets/date-picker/unavailable.svg" alt="">
            <span>{{$t('maxVillages')}}</span>
            <a href="" class="shopping-cart__card__table__need-more-villages__add" @click.prevent="addVillage">{{$t('addMoreVillage')}}</a>
          </div>
        </div>
      </div>

      <div v-if="shoppingCartActive" class="shopping-cart__activities">
        <h1>{{ $t('addMoreFun') }}</h1>
        <activities :open="false"/>
      </div>
    </div>
    <see-conditions-modal ref="conditionsModal"></see-conditions-modal>
    <remove-product-modal ref="removeProductModal" @removeProduct="deleteItem"></remove-product-modal>
  </checkout-cart-layout>
</template>

<script>
  import CheckoutCartLayout from '@/layouts/CheckoutCartLayout';
  import {mapState} from 'vuex';
  import SeeConditionsModal from '@/components/visit-drawer/SeeConditionsModal';
  import Activities from '@/components/shopping-cart/Activities';
  import moment from 'moment';
  import cartManager from "../utils/cartManager";
  import RemoveProductModal from "../components/modal/RemoveProductModal.vue";
  import { LODGING } from '@/utils/catalog';

  export default {
    name: 'ShoppingCart',
    components: {
      RemoveProductModal,
      SeeConditionsModal,
      CheckoutCartLayout,
      Activities,
    },
    metaInfo() {
      return {title: this.$t("tabs.shoppingCart")}
    },
    watch: {
      '$i18n.locale'() {
        moment.locale(this.$i18n.locale);
      },
      'quote.visit.numberOfPersons': function() {
          this.getItems();
      },
      'quote.visit.numberOfDays': function() {
          this.getItems();
      },
      'quote.products.cabins': function() {
          this.getItems();
      },
      'quote.products.canopyPasses': function() {
          this.getItems();
      },
      'quote.products.horsebackRidingPasses': function() {
          this.getItems();
      },
      'quote.products.tipis': function() {
          this.getItems();
      },
      'quote.products.lakeVillages': function() {
          this.getItems();
      },
      'quote.visit.arrivalDate': function() {
          this.dateChanged();
          this.getItems();
      },
      'quote.visit.departureDate': function() {
          this.dateChanged();
          this.getItems();
      },
      'conditions2x3Visible'() {
        if(this.conditions2x3Visible) {
          document.removeEventListener('click', this.onClick, false);
          document.removeEventListener("keyup", this.closeVisitDrawer, false);
          this.$refs.conditionsModal.openModal();
        } else {
          this.$refs.conditionsModal.closeModal();
          setTimeout(() => {
            document.addEventListener('click', this.onClick);
            document.addEventListener("keyup", this.closeVisitDrawer);
          }, 100);
        }
      },
      'quote.products': function() {
          this.getItems();
      },
    },
    data() {
      return {
          items: [],
          changeDateTimeoutObj: null,
          tooltipOptions: {
            content: this.$t('validations.cantRemoveItem'),
            class: 'tooltip-delete',
            delay: 1
          },
          maxCabins: LODGING.CABIN,
          maxTipis: LODGING.TIPI,
          maxLakeVillages: LODGING.LAKE_VILLAGE,
      };
    },
    beforeMount() {
      this.$store.dispatch('hideVisitDrawer');
      this.$store.dispatch('hideMobileDrawer');
    },
    computed: {
      isCapacityFull() {

        if (!this.quote) {
          return false;
        }

        if((this.quote.cabinNumbers && this.quote.cabinNumbers.length > 0)
          || (this.quote.tipiNumbers && this.quote.tipiNumbers.length > 0)
          || (this.quote.lakeVillageNumbers && this.quote.lakeVillageNumbers.length > 0)) {
          return false;
        }

        const haveDates = this.quote && this.quote.visit && this.quote.visit.arrivalDate && this.quote.visit.departureDate && this.quote.visit.arrivalTime;
        return haveDates ? (this.quote.visit.arrivalTime === 'DAY' ? this.$store.getters.capacityFullDay : this.$store.getters.capacityFullNight) : false;
      },
      tipis() {
        const tipis = this.quote.tipiNumbers.map(tipi => "#".concat(tipi.number));
        return `${this.$t('teepe')} ${tipis.slice(0, 3).join(", ")}${tipis.length > 3 ? ', ...' : ''}`;
      },
      cabins() {
        const cabins = this.quote.cabinNumbers.map(tipi => "#".concat(tipi.number));
        return `${this.$t('cabin')} ${cabins.slice(0, 3).join(", ")}${cabins.length > 3 ? ', ...' : ''}`;
      },
      lakeVillages() {
        const lakeVillages = this.quote.lakeVillageNumbers.map(village => "#".concat(village.number));
        return `${this.$t('village')} ${lakeVillages.slice(0, 3).join(", ")}${lakeVillages.length > 3 ? ', ...' : ''}`;
      },
      shoppingCartActive() {
        return this.quote && this.quote.visit && this.quote.visit.numberOfPersons > 0 &&  this.quote.visit.departureDate && localStorage.getItem('CANADAS_SHOPPING_CART');
      },
      needMoreCabins() {
          let cabins = this.quote ? this.quote.products.cabins : 0;
          if (cabins > 0) {
              let numberOfPersons = this.quote.visit.numberOfPersons;
              let personsPerCabins = cabins * 6;
              return numberOfPersons > personsPerCabins;
          }
          return false;
      },
      needMoreVillages() {
        let villages = this.quote ? this.quote.products.lakeVillages : 0;
        if(villages > 0) {
          let numberOfPersons = this.quote.visit.numberOfPersons;
          let personsPerVillages = villages * 6;
          return numberOfPersons > personsPerVillages;
        }
        return false;
      },
      isLodge() {
        return this.isCabin || this.isVillages || this.isTipi;
      },
      isCabin() {
        if (!this.quote) {
          return false;
        }

        const cabins = this.quote ? this.quote.products.cabins : null;

        return cabins;
      },
      isVillages() {
        if (!this.quote) {
          return false;
        }

        const villages = this.quote ? this.quote.products.lakeVillages : null;

        return villages;
      },
      isTipi() {
        if (!this.quote) {
          return false;
        }

        const tipis = this.quote ? this.quote.products.tipis : null;

        return tipis;
      },
      campingPassNotice() {
        if (this.isCabin) {
          return `${this.$t('campingPassCabinNotice')}`;

        } else if (this.isVillages) {
          return `${this.$t('campingPassVillageNotice')}`;

        } else if (this.isTipi) {
          return `${this.$t('campingPassTipiNotice')}`;
        }

        return '';
      },
      arrivalTimeNotice() {
        if (this.isCabin) {
            return `${this.$t('cabinHourArrival')}`;
        } else if (this.isVillages) {
            return `${this.$t('lakeVillageHourArrival')}`;
        } else if (this.isTipi) {
            return `${this.$t('tipiHourArrival')}`;
        }

        return '';
      },
      hasPasses() {
        if(!this.reservation) {
            return false;
        }

        const campingPass = this.items.find(item => item.name === 'campingPass');
        const oneDayPass = this.items.find(item => item.name === "oneDayPass");
        return (oneDayPass && oneDayPass.quantity > 0) || (campingPass && campingPass.quantity > 0);
      },
      unavailableCabins() {
        const unavailableCabins = this.$store.getters.unavailableCabins;
        return unavailableCabins && unavailableCabins.length > 0;
      },
      unavailableTipis() {
        const unavailableTipis = this.$store.getters.unavailableTipis;
        return unavailableTipis && unavailableTipis.length > 0;
      },
      unavailableLakeVillages() {
        const unavailableLakeVillages = this.$store.getters.unavailableLakeVillages;
        return unavailableLakeVillages && unavailableLakeVillages.length > 0;
      },
      ... mapState(['quote', 'isUnavailableCabins', 'isUnavailableTipis', 'isUnavailableLakeVillages',
          'conditions2x3Visible', 'reservationPrices', 'inRemoteActivity', 'yellowDateSelected']),
    },
    mounted() {
      moment.locale(this.$i18n.locale);
      if (this.quote && this.quote.totalAmount > 0) {
        setTimeout(() => {
          this.getItems();
        }, 500);
        this.validReservations(this.quote.id);
        this.onViewCartEvent();
      } else {
        this.$store.dispatch('loadQuote');
      }
    },
    beforeDestroy() {
      this.$store.dispatch('hideVisitDrawer');
      this.$store.dispatch('hideMobileDrawer');
    },
    methods: {
      largeDate(date) {
        const locale = this.$i18n.locale
        return moment(date).locale(locale).format(locale == 'es' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY').replace('.', '')
      },
      mediumDate(date) {
        const locale = this.$i18n.locale
        return moment(date).locale(locale).format(locale == 'es' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY').replace('.', '')
      },
      validReservations(quoteId) {
        this.$store.dispatch('validReservations', quoteId);
      },
      openChooseTipiModal() {
          this.$store.dispatch('updateChooseTipiVisible', true);
      },
      openChooseCabinModal() {
          this.$store.dispatch('updateChooseCabinVisible', true);
      },
      openChooseLakeVillageModal() {
          this.$store.dispatch('updateChooseLakeVillageVisible', true);
      },
      closeCalendar(e) {
        if ((e.target && e.target !== document.getElementById('datePickerDates') && !(document.getElementById('datePickerCalendar') && document.getElementById('datePickerCalendar').contains(e.target))) && this.$refs.datePicker) {
          this.$refs.datePicker.$data.openCalendar = false;
        }
      },
      async openDrawer() {
        await this.$router.push({name: 'homepage'})
        this.$root.$emit('fromShoppingCart')
      },
      canDelete(item) {
        return !(this.items.some(i => i.name === 'teepee' || i.name ===  'cabins') && item.name === 'campingPass')
      },
      validateDeleteItem(item) {
        let validationRequired = cartManager.validateRemoveProduct(this.quote, item);
        if(validationRequired)
          this.$refs.removeProductModal.openModal(validationRequired, item);
        else
          this.deleteItem(item);
      },
      async deleteItem(item) {
          this.onRemoveFromCartEvent(item);
          await cartManager.removeProduct(this.quote, item);
          await this.getItems();
      },
      getPricePerItem(item) {
        return (item && item.quantity && item.totalAmount) ? item.totalAmount / item.quantity : 0;
      },
      getItemByKey(key) {
        const item = this.quote && this.quote.productItems ? this.quote.productItems.find(i => i.key === key) : null;
        return item ? {price: item.price, quantity: item.quantity, totalAmount: item.totalAmount, key: item.key } : null;
      },
      getVisitActivityByType(type) {
        let activity = null;
        if(this.quote && this.quote.visitActivityReservations && this.quote.visitActivityReservations.length > 0) {
          const reservationsByType = this.quote.visitActivityReservations
              .filter(r => r.visitActivitySchedule.visitActivity.type == type);

          if(reservationsByType.length > 0) {
            activity = {
              quantity: 0,
              hours: 0,
              price: 0,
              type: type
            };

            reservationsByType.forEach(r => {
              activity.quantity = r.quantity + r.borrowedUnits;
              activity.hours += r.hours;
              activity.price = r.activityPrice;
            })
          }

        }
        return activity;
      },
      async getItems() {
        if (this.quote && !this.quote.visit && this.quote.totalAmount == 0) {
          await this.$store.dispatch('updateQuote', null);
          return;
        } else if (!this.quote) {
          return;
        }

        let items = [];
        if(this.quote.visit.numberOfPersons < 1)
          return items;

        const dayPass = this.getItemByKey('day_pass');
        const campingPass = this.getItemByKey('camping_pass');
        const cabin = this.getItemByKey('cabin');
        const tipi = this.getItemByKey('tipi');
        const lakeVillage = this.getItemByKey('lake_village');
        const atv = this.getVisitActivityByType('ATV');
        const canopyNightPasses = this.getVisitActivityByType('CANOPY_TOUR_NIGHT');
        const canopyPasses = this.getVisitActivityByType('CANOPY_TOUR');
        const horsebackRides = this.getVisitActivityByType('HORSEBACK_RIDING');

        if(dayPass) {
          items.push({
            order: 0,
            icon: 'day-pass',
            name: 'oneDayPass',
            quantity: dayPass.quantity,
            price: dayPass.price,
            key: dayPass.key,
            setProduct: async (personsQuantity) => {
              if(personsQuantity < 1)
                await this.$store.dispatch('updateQuote', null)
              else {
                await this.$store.dispatch('updateVisit', {numberOfPersons: personsQuantity});
                await this.getItems();
              }
            }
          });
        }

        if(campingPass) {
          items.push({
            order: 1,
            icon: 'camping-pass',
            name: 'campingPass',
            quantity: campingPass.quantity,
            price: campingPass.price,
            key: campingPass.key,
            setProduct: async (personsQuantity) => {
              if(personsQuantity < 1)
                await this.$store.dispatch('updateQuote', null)
              else {
                await this.$store.dispatch('updateVisit', {numberOfPersons: personsQuantity});
                await this.getItems();
              }
            }
          });
        }

        if(cabin) {
          items.push({
            order: 2,
            icon: 'cottage',
            name: 'ecotouristCabins',
            quantity: cabin.quantity,
            maxQuantity: this.maxCabins,
            price: cabin.price,
            key: cabin.key,
            setProduct: async (cabinsQuantity) => {
              if(cabinsQuantity < 1)
                await this.$store.dispatch('updateQuote', null)
              else
                await this.$store.dispatch('updateProducts', {cabins: cabinsQuantity});

            }
          });
        }

        if(tipi) {
          items.push({
            order: 3,
            icon: 'tepee',
            name: 'teepees',
            quantity: tipi.quantity,
            maxQuantity: this.maxTipis,
            price: tipi.price,
            key: tipi.key,
            setProduct: async (tipiQuantity) => {
              if(tipiQuantity < 1)
                await this.$store.dispatch('updateQuote', null)
              else
                await this.$store.dispatch('updateProducts', {tipis: tipiQuantity});
            }
          });
        }

        if (lakeVillage) {
          items.push({
            order: 4,
            icon: 'lake-village',
            name: 'lakeVillages',
            quantity: lakeVillage.quantity,
            maxQuantity: this.maxLakeVillages,
            price: lakeVillage.price,
            setProduct: async (lakeVillageQuantity) => {
              if(lakeVillageQuantity < 1)
                await this.$store.dispatch('updateQuote', null)
              else
                await this.$store.dispatch('updateProducts', {lakeVillages: lakeVillageQuantity});
            }
          });
        }

        if(canopyPasses) {
          items.push({
            order: 5,
            icon: 'canopy',
            name: 'canopy',
            quantity: canopyPasses.quantity,
            price: canopyPasses.price,
            key: canopyPasses.type,
            setProduct: async (canopyQuantity) => {
              await this.$store.dispatch('updateProducts', {canopyPasses: canopyQuantity});
            }
          });
        }

        if(canopyNightPasses){
          items.push({
            order: 6,
            icon: 'canopy-night',
            name: 'canopyNight',
            quantity: canopyNightPasses.quantity,
            price: canopyNightPasses.price,
            key: canopyNightPasses.type,
            setProduct: async (canopyNightPassesQuantity) => {
              await this.$store.dispatch('updateProducts', {canopyNightPasses: canopyNightPassesQuantity});
            }
          });
        }

        if(horsebackRides) {
          items.push({
            order: 7,
            icon: 'horse-ride',
            name: 'horseRide',
            quantity: horsebackRides.quantity,
            price: horsebackRides.price,
            key: horsebackRides.type,
            setProduct: async (horsebackRidingQuantity) => {
              await this.$store.dispatch('updateProducts', {horsebackRidingPasses: horsebackRidingQuantity});
            }
          });
        }

        if(atv) {
          items.push({
            order: 8,
            icon: 'atv',
            name: 'atvs',
            quantity: atv.quantity,
            hours: atv.hours,
            price: atv.price,
            key: atv.type,
            setProduct: async (atvsQuantity) => {
              await this.$store.dispatch('updateProducts', {atvs: atvsQuantity});
            }
          });
        }

        items = this.sortItems(items);
        this.$set(this, 'items', items);
      },
      dateChanged() {
        if(this.changeDateTimeoutObj){
            clearTimeout(this.changeDateTimeoutObj);
        }
        this.changeDateTimeoutObj = setTimeout(async () => {
            this.onGenericEvent(`modificar fechas`, {event_category: 'carrito', event_label: `calendario`});
        }, 300);
      },
      async addCabin() {
        let products = this.quote.products;
        products.cabins = products.cabins + 1;
        products.tipis = this.quote.products.tipis;
        products.lakeVillages = this.quote.products.lakeVillages;
        await this.$store.dispatch('updateProducts', products);
      },
      async addVillage() {
        let products = this.quote.products;
        products.lakeVillages = products.lakeVillages + 1;
        products.tipis = this.quote.products.tipis;
        products.cabins = this.quote.products.cabins;
        await this.$store.dispatch('updateProducts', products);
      },
      saveReservations(stayIds) {
        let request = {
          quoteId: this.quote.id,
          stayIds: stayIds
        }
        this.$store.dispatch('saveReservations', request);
      },
      sortItems(activities) {
        return activities.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
      },
      onRemoveFromCartEvent(item) {
        item.totalAmount = item.price * item.quantity;
        this.$root.$emit('onRemoveFromCartEvent', item);
      },
      onViewCartEvent() {
        this.$root.$emit('onViewCartEvent', this.quote.productItems, this.quote.totalAmount);
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
      displayDrawer() {
        document.documentElement.clientWidth > 1330 ? this.$store.dispatch('showVisitDrawer') : this.$store.dispatch('showMobileDrawer');
      }
    }
  };
</script>

<style lang="scss" scoped>
  $dark-color-table: $dark-brown-color;
  .shopping-cart {
    letter-spacing: 0.05em;
    display: grid;
    &__empty {
      width: 100%;
      display: grid;
      justify-items: center;
      img {
        margin: rem(56px) 0;
      }
      button {
        width: 240px;
      }
    }
    &__content {
      display: grid;
      grid-template-rows: min-content 1fr;
      grid-row-gap: rem(26px);
      @include respond-to("medium and down"){
        grid-row-gap: 1rem;
      }
      h1 {
        font-size: 1.5rem;
        line-height: rem(30px);
      }
    }
    &__card {
      background-color: white;
      border: 1px solid rgba(30, 15, 0, 0.2);
      border-radius: rem(16px);
      color: $dark-brown-color;
      .delete {
        height: min-content;
        text-decoration: underline;
        background-color: transparent;
        padding: 0;
        width: fit-content;
        font-family: Montserrat;
        font-weight: 500;
        color: #FF3030;
        text-transform: capitalize;
        cursor: pointer;
        font-size: rem(16px);
        &.disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }

        @include respond-to('medium and down') {
          justify-self: center;
          align-self: center;
          height: fit-content;
        }
      }
      &.disabled {
        pointer-events: none;
      }
      &__dates {
        position: relative;
        .date-picker {
          display: grid;
          grid-row-gap: .8rem;
          @include respond-to("small and down") {
            grid-row-gap: 1rem;
          }
          .dates {
            display: grid;
            grid-template-columns: 1.5rem auto 1rem auto 1fr;
            span { white-space: nowrap }
            @include respond-to("small and down") {
              grid-template-columns: auto 1rem auto 1fr;
              .arrival-time {
                display: none;
              }
              .desktop { display: none; }
            }
            @include respond-to("medium and up") {
              .mobile {
                display: none;
              }
            }
            align-items: center;
            grid-column-gap: .5rem;
            font-family: $font-secondary;
            font-weight: normal;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            .arrival-time {
              opacity: .6;
            }
          }
          h3 {
            text-transform: none;
            opacity: .6;
            margin: 0;
            font-family: $font-primary;
            font-weight: 500;
          }
        }
        &__edit-visit {
          position: absolute;
          right: 0;
          top: 0;
          display: grid;
          grid-template-columns: 1.5rem 1fr;
          grid-column-gap: .5rem;
          align-items: center;
          text-decoration: none;
          color: $blue-great-color;
          font-weight: 500;
        }
        display: grid;
        .cart { justify-self: end }
        h3 {
          font-weight: 500;
          font-size: rem(16px);
          line-height: rem(16px);
          letter-spacing: 0.05em;
          margin-bottom: rem(12px);
        }
        @include respond-to("large and down") {
          grid-template-columns: 1fr !important;
          grid-row-gap: 1rem;
        }
        &__unavailable {
          display: grid;
          grid-row-gap: .25rem;
          align-items: end;
          &__product {
            display: grid;
            grid-template-columns: 1.5rem 1fr;
            grid-column-gap: .5rem;
            text-align: left;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            span {
              color: #FF3030;
            }
          }
        }
        &__camping-pass {
          display: grid;
          grid-template-columns: 1.5rem 1fr;
          grid-column-gap: .5rem;
          text-align: left;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          span {
            color: $light-gray;
          }
        }
      }
      &__table {
        padding-bottom: 0;
        grid-auto-rows: min-content;
        &__header, &__body {
          &__row {
            display: grid;
            grid-template-columns: 2.9fr 3fr 1.5fr 1.2fr;
            grid-template-areas: "quantity description price total";
            align-items: center;
            &.tipi, &.cabin, &.lake-village {
              grid-auto-rows: min-content;
              grid-template-areas: "quantity description price total" "empty choose empty empty";
              grid-row-gap: 5px;
            }
            .quantity {
              grid-area: quantity;
            }
            .description {
              grid-area: description;
              align-items: start;
              .hours {
                margin-top: rem(8px);
                font-size: rem(16px);
                font-family: $font-primary;
                text-transform: none;
                text-align: left;
                width: max-content;
              }
            }
            .price {
              grid-area: price;
            }
            .total {
              grid-area: total;
            }
            .choose {
              text-transform: none;
              grid-area: choose;
              grid-column: 2;
              justify-self: start;
              display: grid;
              align-items: center;
              display: grid;
              grid-template-columns: auto auto;
              grid-column-gap: 1rem;
              font-family: $font-primary;
              margin-left: 40px;
              a {
                text-decoration: none;
                font-weight: 500;
                color: $blue-great-color;
                cursor: pointer;
              }
            }
            :not(:first-child) {
              text-align: right;
            }
            :nth-child(2) {
              text-align: left;
            }
          }
        }
        &__header {
          &__row {
            padding-bottom: 1rem;
            line-height: 1rem;
          }
        }
        &__body {
          &__row {
            padding: 1rem 0;
            &:last-child {
              padding-bottom: 0;
            }
            border-top: 1px dashed $dark-color-table;
            font-weight: bold;
            font-size: rem(16px);
            line-height: rem(20px);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            display: grid;
            align-items: center;
            .add-more-div {
              height: min-content;
              display: grid;
              grid-template-columns: 1fr 3.5fr;
              grid-column-gap: 1rem;
              align-items: center;
              .add-more-counter{
                justify-self: center;
              }
              .counter {
                justify-items: left;
              }

            }
            div:nth-child(2) {
              display: grid;
              grid-template-columns: 2rem 1fr;
              grid-column-gap: rem(8px);
              align-items: center;
              justify-items: start;
            }
            div {
              font-family: 'Chau Philomene One', sans-serif;
              font-weight: normal;
            }
          }
        }
        &__need-more-cabins, &__need-more-villages {
            padding: .6rem 0 2rem 0;
            letter-spacing: 0.05em;
            color: #FF3030 !important;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            display: grid;
            grid-template-columns: 1.5rem 1fr;
            grid-column-gap: .5rem;
            grid-template-areas: "icon text" "empty add";
            align-items: start;
            text-align: left;
            &__add {
                grid-area: add;
                text-decoration: none;
                color: #0075FF;
            }
        }
      }

    }
    &__unavaible {
        display: grid;
        grid-row-gap: 1rem;
        width: 100%;
        &__reservation {
            letter-spacing: 0.05em;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            display: grid;
            grid-template-columns: 1.5rem 1fr;
            grid-column-gap: .5rem;
            align-items: start;
            text-align: left;
            span {
                color: #FF3030;
            }
        }
        div:first-child {
          padding-top: 2rem;
        }
        div:last-child {
          padding-bottom: rem(26.24px);
        }
    }
    &__activities {
      display: grid;
    }
    @include respond-to("small and down") {
        &__unavaible { padding-top: .5rem; }
    }
    @include respond-to("medium and down") {
      grid-gap: 2rem;
      &__content {
        h1 { display: none }
      }
      &__card {
        padding: rem(16px);
        padding-bottom: 0;
        &__table { display: none }
        &__dates {
          margin-bottom: rem(12px);
          grid-row-gap: 10px;
        }
        &__item {
          display: grid;
          grid-template-columns: 1fr min-content;
          grid-row-gap: 1rem;
          padding: 1rem 0;
          border-top: 1px dashed black;
          .item {
            &__amount{ display: none; }
            &__header, &__total-amount {
              display: grid;
              grid-template-columns: 1fr 84px;
              grid-column-gap: 1rem;
              align-items: center;
              line-height: 1rem;
              &__name {
                font-family: $font-secondary;
                text-transform: uppercase;
                display: grid;
                grid-template-columns: 1.5rem 1fr;
                grid-column-gap: .5rem;
                align-items: center;
                line-height: rem(22px);
              }
              div:last-child {
                font-family: $font-secondary;
                font-weight: normal;
                justify-self: end;
              }
              .add-more {
                min-width: 5.3rem;
              }
            }
            &__header, &__options{
              grid-column: 1 / span 2;
            }
            &__header {
              display: grid;
              grid-row-gap: 1rem;
              .add-more-counter{display: none;}
              .choose {
                display: grid;
                grid-template-columns: max-content auto;
                grid-column-gap: 1rem;
                font-family: $font-primary !important;
                text-transform: none;
                grid-column: 1/3;
                line-height: 1rem;
                width: 100%;
                a {
                  text-decoration: none;
                  font-weight: 500;
                  color: $blue-great-color;
                }
              }
            }
            &__options{
              &.removes {
                padding-top: 0.5rem;
                margin-bottom: -2px;
              }
            }
            &__options{ justify-self: center; }
            &__total-amount {
              >div:first-child{display: none;}
              grid-template-columns: 1fr;
            }
          }
        }
        &__arrival-time{
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          column-gap: 8.8px;
          span{
            font-weight: 500;
            opacity: 0.5;
          }
          .notice {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: $light-gray;
            text-align: justify;
          }
        }
      }
      &__activities {
        grid-gap: 2rem;
      }
    }
    @include respond-to("large and up") {
      grid-gap: 2rem;
      &__card {
        padding: 1.5rem;
        &__items { display: none }
        &__arrival-time{display: none;}
        &__dates {
          margin-bottom: 2rem;
          grid-template-columns: 1fr auto;
          grid-column-gap: 1rem;
          &.unavailable {
            grid-template-columns: min-content 1fr;
            grid-column-gap: 2rem;
          }
        }
      }
    }
    @include respond-to("medium and up") {
      &__activities {
        grid-gap: 1.5rem;
      }
    }
  }
</style>

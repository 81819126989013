<template>
    <div class="choose-cabin">
        <div class="choose-cabin__content desktop">
            <img :src="require(`@/assets/reservations/cabins-map--${locale}.png`)" alt="">
            <svg class="svg" width="622" height="234" viewBox="0 0 622 234" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g class="cabin" v-for="(cabin, index) in cabinList" :key="index" @click.prevent="cabin.available && !pendingCabinsSelected(cabin.staying.id) && updateCabins(cabin.staying.id, 'Cabin')" :class="{
                    disabled: !cabin.available,
                    selected: pendingCabinsSelected(cabin.staying.id)
                }">
                    <circle v-if="cabins[cabin.staying.name]" class="circle" :class="cabin.staying.name" :cx="cabins[cabin.staying.name].circleCx" :cy="cabins[cabin.staying.name].circleCy" r="14.5" fill="white" stroke="#1E0F00"/>
                    <text
                        v-if="cabins[cabin.staying.name]"
                        class="text"
                        :x="cabins[cabin.staying.name].circleCx"
                        :y="cabins[cabin.staying.name].circleCy"
                        text-anchor="middle"
                        dominant-baseline="middle"
                        transform="translate(0, 2)"
                    >
                        {{ getCabinNumber(cabin) }}
                    </text>
                </g>
            </svg>
        </div>
        <div class="choose-cabin__content mobile">
            <img :src="require(`@/assets/reservations/cabins-map-mobile--${locale}.png`)" alt="">
            <svg class="svg" width="221" height="584" viewBox="0 0 221 584" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g class="cabin" v-for="(cabin, index) in cabinList" :key="index" @click.prevent="cabin.available && !pendingCabinsSelected(cabin.staying.id) && updateCabins(cabin.staying.id, 'Cabin')" :class="{
                    disabled: !cabin.available,
                    selected: pendingCabinsSelected(cabin.staying.id)
                }">
                    <circle v-if="cabins[cabin.staying.name]" class="circle" :class="cabin.staying.name" :cx="cabins[cabin.staying.name].mobileCircleCx" :cy="cabins[cabin.staying.name].mobileCircleCy" r="13.5983" fill="white" stroke="#1E0F00"/>
                    <text
                        v-if="cabins[cabin.staying.name]"
                        class="text"
                        :x="cabins[cabin.staying.name].mobileCircleCx"
                        :y="cabins[cabin.staying.name].mobileCircleCy"
                        text-anchor="middle"
                        dominant-baseline="middle"
                        transform="translate(0, 2)"
                    >
                        {{ getCabinNumber(cabin) }}
                    </text>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
    const cabins = {
        C1: {
            circleCx: 578,
            circleCy: 211,
            mobileCircleCx: 198.634,
            mobileCircleCy: 41.8533,
        },
        C2: {
            circleCx: 547,
            circleCy: 218,
            mobileCircleCx: 205.196,
            mobileCircleCy: 70.9246,
        },
        C3: {
            circleCx: 516,
            circleCy: 219,
            mobileCircleCx: 206.134,
            mobileCircleCy: 99.9969,
        },
        C4: {
            circleCx: 484,
            circleCy: 214,
            mobileCircleCx: 201.446,
            mobileCircleCy: 130.008,
        },
        C5: {
            circleCx: 454,
            circleCy: 204,
            mobileCircleCx: 192.067,
            mobileCircleCy: 158.141,
        },
        C6: {
            circleCx: 398,
            circleCy: 172,
            mobileCircleCx: 162.059,
            mobileCircleCy: 210.659,
        },
        C7: {
            circleCx: 373,
            circleCy: 150,
            mobileCircleCx: 141.427,
            mobileCircleCy: 234.104,
        },
        C8: {
            circleCx: 350,
            circleCy: 126,
            mobileCircleCx: 118.919,
            mobileCircleCy: 255.674,
        },
        C9: {
            circleCx: 332,
            circleCy: 101,
            mobileCircleCx: 95.4734,
            mobileCircleCy: 272.554,
        },
        C10: {
            circleCx: 310,
            circleCy: 74,
            mobileCircleCx: 70.1531,
            mobileCircleCy: 293.186,
        },
        C11: {
            circleCx: 248,
            circleCy: 57,
            mobileCircleCx: 54.2078,
            mobileCircleCy: 351.331,
        },
        C12: {
            circleCx: 217,
            circleCy: 62,
            mobileCircleCx: 58.8992,
            mobileCircleCy: 380.403,
        },
        C13: {
            circleCx: 187,
            circleCy: 65,
            mobileCircleCx: 61.7117,
            mobileCircleCy: 408.537,
        },
        C14: {
            circleCx: 158,
            circleCy: 67,
            mobileCircleCx: 63.5867,
            mobileCircleCy: 435.734,
        },
        C15: {
            circleCx: 128,
            circleCy: 65,
            mobileCircleCx: 61.7117,
            mobileCircleCy: 463.868,
        },
        C16: {
            circleCx: 94,
            circleCy: 60,
            mobileCircleCx: 57.0242,
            mobileCircleCy: 495.754,
        },
        C17: {
            circleCx: 66,
            circleCy: 49,
            mobileCircleCx: 46.7078,
            mobileCircleCy: 522.013,
        },
        C18: {
            circleCx: 40,
            circleCy: 34,
            mobileCircleCx: 32.6414,
            mobileCircleCy: 546.395,
        },
        C19: {
            circleCx: 15,
            circleCy: 15,
            mobileCircleCx: 14.8211,
            mobileCircleCy: 569.841,
        },
    }

    export default {
    name: 'ChooseCabinModal',
        props: ['cabinList'],
        data() {
            return {
                currentPendingTipis: [],
                currentPendingCabins: [],
                auxCurrentPendingCabins: []
            }
        },
        watch: {
            '$store.getters.chooseCabinVisible'() {
                this.openModal();
            }
        },
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            cabins() {
                return cabins;
            },
            quote() {
                return this.$store.getters.quote;
            }
        },
        mounted() {
            this.getCurrentPendingCabins();
        },
        methods: {
            getCurrentPendingCabins() {
                this.currentPendingCabins = this.quote ? this.quote.cabinNumbers.map(c => c.id) : [];
                this.currentPendingTipis = this.quote ? this.quote.tipiNumbers.map(c => c.id) : [];
                this.auxCurrentPendingCabins = [].concat(this.currentPendingCabins);
            },
            pendingCabinsSelected(id) {
                return this.currentPendingCabins.find(stayingId => stayingId === id);
            },
            updateCabins(id) {
                this.currentPendingCabins.shift();
                this.currentPendingCabins = this.currentPendingCabins.concat([id]);
                this.$emit('valid', !this.checkIfhaveNewCabins());
            },
            getArray(list) {
                let array = [];
                list.forEach(element => {
                    if(array.indexOf(element) == -1) {
                        array.push(element);
                    }
                });
                return array;
            },
            async save() {
                let request = {
                    quoteId: this.quote.id,
                    stayIds: this.currentPendingCabins.concat(this.currentPendingTipis)
                }
                await this.$store.dispatch('saveReservations', request);
                this.validReservation();
            },
            async validReservation() {
                let unavailable = await this.$store.dispatch('validReservations', this.quote.id);
                if (unavailable.cabins.length == 0 && unavailable.tipis.length == 0) {
                    this.$store.dispatch('updateChooseCabinVisible', false);
                } else {
                    this.getCurrentPendingCabins();
                    this.$emit('valid', false);
                }
            },
            checkIfhaveNewCabins() {
                let list = [].concat(this.currentPendingCabins);
                let auxList = [].concat(this.auxCurrentPendingCabins);
                list.sort();
                auxList.sort();
                return list.length == auxList.length && list.every(function(v,i) { return v === auxList[i] } );
            },
            getCabinNumber(cabin) {
                if (!cabin || !cabin.staying || !cabin.staying.name) {
                    return null;
                }

                return cabin.staying.name.replace('C', '')
            }
        }
    }
</script>


<style lang="scss" scoped>
.choose-cabin {
    background-color: white;
    display: grid;
    grid-row-gap: 1.2rem;
    &__content {
        position: relative;
        .svg {
            position: absolute;
            .cabin {
                cursor: pointer;
                .cirlce {
                    stroke: $dark-brown-color;
                    fill: white;
                    cursor: pointer;
                }
                .text {
                    fill: $dark-brown-color;
                    font-family: $font-secondary;
                    font-size: 16px;
                }
                &.disabled {
                    cursor: not-allowed;
                    .circle { stroke: #D2CFCC; }
                    .text { fill: #D2CFCC; }
                }
                &.selected {
                    .circle { fill: $red-color2; }
                    .text { fill: white; }
                }
            }
        }
        @include respond-to("medium and up") {
            &.mobile { display: none }
            img {
                width: 650px;
            }
            .svg {
                width: 95.5%;
                height: 70.5%;
                top: 0%;
                right: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
        @include respond-to("small and down") {
            &.desktop { display: none }
            .svg {
                width: 70.5%;
                height: 95.5%;
                top: 1.5%;
                left: .5%;
            }
        }
    }
}
</style>

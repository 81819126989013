<template>
    <products-services selected-product="trojaRestaurant">
        <div class="product-summary__details" v-if="currentProduct">
            <gallery>
                <template slot="small-image">
                    <img :src="require('@/assets/products/restaurant/Troja-restaurant-' + gallery[0] + '.jpg')" alt="">
                </template>
                <template slot="gallery-images">
                    <img class="slide" v-for="(picture, i) in gallery" :key="i"
                        :src="require('@/assets/products/restaurant/Troja-restaurant-' + picture + '.jpg')" alt="">
                </template>
            </gallery>
            <div class="product-summary__details__title">
                <h1>{{ $t(currentProduct.title) }}</h1>
            </div>
            <div class="product-summary__details__content">
                <div class="product-summary__details__content__information">
                    <div class="product-summary__details__content__information__description">
                        <ul>
                            <li>{{ $t(description.text1) }}</li>
                            <li>{{ $t(description.text2) }}</li>
                            <li>{{ $t(description.text3) }}</li>
                            <li>{{ $t(description.text4) }}</li>
                        </ul>
                        <button class="btn btn--primary--outer" @click="openMenu">
                            {{ $t(description.button) }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </products-services>
</template>   

<script>
import Gallery from '@/components/Gallery';
import ProductsServices from '@/views/products/ProductsServices.vue';

export default {
    name: 'TrojaRestaurant',
    components: { ProductsServices, Gallery },
    metaInfo() {
        return {title: this.$t("tabs.restaurant")}
    },
    data() {
        return {
            menuLink: process.env.VUE_APP_https://storage.googleapis.com/lascanadas-devstg-bucket-images/assets/Propuesta_Menu%CC%81_2025_La_Troja_Restaurante_Actualizacio%CC%81n_Abril_2025.pdf,
            currentProduct: null,
            informationHidden: true,
            gallery: [1, 2, 3, 4, 5],
            description: {
                text1: 'productPages.restaurant.text1',
                text2: 'productPages.restaurant.text2',
                text3: 'productPages.restaurant.text3',
                button: 'productPages.restaurant.button',
            }
        }
    },
    mounted() {
        this.currentProduct = { id: 'trojaRestaurant', title: 'trojaRestaurant', imageName: 'live-music' };
    },
    methods: {
        openMenu() {
            const currentTimestamp = Date.now();
            window.open(`${this.menuLink}?timestamp=${currentTimestamp}`, '_blank');
        }
    }
};
</script>

<style lang="scss" scoped>
.product-summary {
    &__details {
        &__title {
            @include respond-to("medium and up") {
                margin-top: 0px;
            }
        }

        &__content {
            &__information {
                &__description {
                    @include respond-to("small and down") {
                        margin-top: -5px;
                    }

                    button {
                        width: 188px;
                        cursor: pointer;
                    }
                }
            }

            &__services {
                height: rem(250px);
            }
        }
    }
}
</style>
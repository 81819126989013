<template>
  <default-layout>
    <div class="home">
      <div class="home__welcome">
        <div class="home__welcome__content">
          <img class="home__welcome__fallback" src="@/assets/home-fallback.png" alt="">
          <iframe id="homeIframe" class="home__welcome__video" :src="videoSource"
            frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          <div class="home__welcome__video-button" :class="{'button-down': isTextDown}">
          </div>
          <div class="home__welcome__header" >
            <div class="home__welcome__header__wrapper" :class="{'text-down': isTextDown}">
              <p>{{ $t('theOnlyPlaceOfferingFun')}}</p>
              <button  @click.prevent="openVideoPlayer"><img src="@/assets/play.svg"><div>{{ $t('playVideo')}}</div></button>
            </div>
          </div>

        </div>
      </div>
      <column-block class="home__activity activities-in-nature">

        <div class="home__activity__card">
          <div class="home__activity__title">
            <img src="../assets/campfire.svg" alt="">
            <span>{{ $t('fun.mobile.earth')}}</span>
          </div>
          <p>{{ $t('descriptionProductsEarth') }}</p>
          <a href="" class="home__activity__picture ground" @click.prevent="seeMoreEvent('tierra'); $router.push({ name: 'product.earth' })">
            <router-link class="btn btn--white--outer" to="product.earth">
              {{ $t('seeMore')}}<img src="@/assets/arrow-icon.svg" class="arrow" alt="">
            </router-link>
          </a>
        </div>

        <div class="home__activity__card water">
          <div class="home__activity__title">
            <img src="../assets/water.svg" alt="">
            <span>{{ $t('fun.mobile.water')}}</span>
          </div>
          <p>{{ $t('descriptionProductsWater') }}</p>
          <a href="" class="home__activity__picture water" @click.prevent="seeMoreEvent('agua'); $router.push({ name: 'product.water' })">
            <router-link class="btn btn--white--outer" to="/products">
            {{ $t('seeMore')}}<img src="@/assets/arrow-icon.svg" class="arrow" alt="">
          </router-link>
          </a>
        </div>

        <div class="home__activity__card air">
          <div class="home__activity__title">
            <img src="../assets/air.svg" alt="">
            <span>{{ $t('fun.mobile.air') }}</span>
          </div>
          <p>{{ $t('descriptionProductsAir') }}</p>
          <a href="" class="home__activity__picture air" @click.prevent="seeMoreEvent('aire'); $router.push({ name: 'product.air' })">
            <router-link class="btn btn--white--outer" to="product.air">
              {{ $t('seeMore')}}<img src="@/assets/arrow-icon.svg" class="arrow" alt="">
            </router-link>
          </a>
        </div>
      </column-block>

      <column-block class="home__more-activities">
        <div class="home__more-activities__card lodging">
          <div class="home__more-activities__title">
            <img src="../assets/products/cabins--icon--gray.svg" alt="">
            <span>{{ $t('lodging')}}</span>
          </div>
          <p>{{ $t('descriptionLodging') }}</p>
          <a href="" class="home__more-activities__picture lodging" @click.prevent="seeMoreEvent('hospedaje'); $router.push({ name: 'lodging' })">
            <router-link class="btn btn--white--outer" to="/products">
            {{ $t('seeMore')}}<img src="@/assets/arrow-icon.svg" class="arrow" alt="">
          </router-link>
          </a>
        </div>

        <div class="home__more-activities__card other-services">
          <div class="home__more-activities__title">
            <img src="../assets/products/other-activities.svg" alt="">
            <span>{{ $t('other-services') }}</span>
          </div>
          <p>{{ $t('descriptionOtherServices') }}</p>
          <a href="" class="home__more-activities__picture other-services special-image" @click.prevent="seeMoreEvent('otros-servicios'); $router.push({ name: 'services' })">
            <router-link class="btn btn--white--outer" to="product">
              {{ $t('seeMore')}}<img src="@/assets/arrow-icon.svg" class="arrow" alt="">
            </router-link>
          </a>
        </div>
      </column-block>
      <video-player ref="videoPlayer"></video-player>
    </div>
  </default-layout>
</template>

<script>
  import DefaultLayout from '@/layouts/DefaultLayout';
  import ColumnBlock from '@/components/columns/ColumnBlock';
  import VideoPlayer from '@/components/VideoPlayer';

  export default {
    components: {DefaultLayout, ColumnBlock, VideoPlayer},

    metaInfo: {
      title: 'Las Cañadas Campamento',
      titleTemplate: null,
    },
    data () {
      return {
        isTextDown: false,
        videoUrl: process.env.VUE_APP_https://player.vimeo.com/video/971295242?h=903e1567ba&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479,
        videoSettings: "&muted=true&loop=true&autoplay=1&title=0&byline=0&portrait=0&controls=0&sidedock=0"
      }
    },
    computed: {
      videoSource() {
        return this.videoUrl.concat(this.videoSettings);
      }
    },
    mounted() {
      setTimeout(() => {
        this.isTextDown = true
      }, 10000);
    },
    methods: {
      seeMoreEvent: function(type) {
        this.onGenericEvent(`${type} - ver más`, {event_category: 'homepage', event_label: `bloque ${type}`});
      },
      onGenericEvent(event, command) {
        this.$root.$emit('onGenericEvent', event, command);
      },
      openVideoPlayer() {
        this.$refs.videoPlayer.open();
      }
    }
  };


</script>

<style lang="scss" scoped>

  .home {
    display: grid;
    &__welcome {
      position: relative;
      &__video {
        position: absolute;
        z-index: 2;
        bottom: 0;
        width: 100vw;
        height: 57vw;
        top: 50%;
        transform: translateY(-50%);
        @include respond-to("medium and up") { left: 0 }
        @include respond-to("large") {
          height: 120%;
          width: 120%;
          transform: translate(-10%, -50%);
        }
        @include respond-to("small and down") {
          top: 0;
          right: 0;
          width: 250%;
          height: 100%;
          transform: translateX(25%);
        }
      }

      &__content {
        position: relative;
        height: 510px;
        overflow: hidden;
        @media only screen and (min-width: 1441px){
          height: 700px;
        }
        @include respond-to("medium and up") {
          width: 100%;
          left: 0;
        }
        @include respond-to("small and down") {
          width: 100%;
          height: 0;
          padding-top: 100%;
        }
      }
      &__fallback {
        width: 100%;
        height: calc(100% - 3px);
      }
      &__fallback {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        right: 0;
      }
      &__header {
        top: 0;
        font-family: Cubano;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;

        &__wrapper {
          display: grid;
          grid-row-gap: 16px;
          justify-items: center;
          align-content: center;
          height: 100%;
          animation-name: disappear;
          animation-duration: 10s;

          @keyframes disappear {
            0%{opacity: 1;}
            25%{opacity: 1;}
            75%{opacity: 1;}
            85%{opacity: 1;}
            100%{opacity: 0;}
          }

          @include respond-to("medium and down") {
            grid-row-gap: 8px;
          }

          @include respond-to("small and down") {
            grid-row-gap: 0;
          }

          p {
            left: 0;
            right: 0;
            margin: 0 auto;
            color: #fff;
            width: rem(925px);
            text-transform: uppercase;
            font-size: rem(48px);
            line-height: rem(58px);

            @include respond-to("medium and down") {
              width: rem(255px);
              font-size: rem(16px);
              line-height: rem(19px);
            }
          }

          button {
            border-radius: 8px;
            cursor: pointer;
            border: 2px solid white;
            padding: 0 16px;
            justify-content: center;
            align-items: center;
            background: none;
            line-height: 0;
            display: grid;
            grid-template-columns: 24px 1fr;
            grid-column-gap: 8px;
            max-height: 32px;

            @include respond-to("medium and down") {
              font-size: 12px;
              grid-template-columns: 14px 1fr;
              grid-column-gap: 4px;
            }
          }

          &.text-down {
            padding: 2rem;
            align-content: end;
            grid-template-areas: "button" "text" ;


            @include respond-to("small and down") { padding-bottom: 0 }
            p {
              grid-area: text;
              width: 100%;
              font-size: 2rem;
              line-height: rem(38px);
              animation-name: disappears;
              animation-duration: 1s;

              @keyframes disappears {
                from { opacity: 0 }
                to { opacity: 1 }
              }

              @include respond-to("small and down") {
                width: 100%;
                font-size: 1rem;
                line-height: rem(19px);
                padding: 1rem;
              }

              @include respond-to("medium") {
                font-size: rem(20px);
                line-height: rem(19px);
              }
            }

            button {
              grid-area: button;
              animation-name: disappears;
              animation-duration: 1s;
              @keyframes disappears {
                from { opacity: 0 }
                to { opacity: 1 }
              }
            }
          }

        }
      }
    }

    &__activity, &__welcome, &__more-activities {
      text-align: center;
    }

    &__activity, &__more-activities {
      @include respond-to("medium and down") {
        grid-template-columns: 1fr;
        grid-row-gap: rem(59px);
        margin-top: rem(52px);
      }
      &__picture {
        align-self: end;
      }
      &__title {
        @include respond-to("large and up") {
          margin-bottom: rem(19px);
        }
        @include respond-to("small and down") {
          margin-bottom: rem(8px);
        }
        img, span {
          vertical-align: middle;
          display: inline-block;
        }
        img {
          margin-right: rem(8px);
          width: rem(48px);
          @include respond-to("small and down") {
            width: rem(48px);
            height: rem(48px);
            margin-bottom: 0;
          }
        }
        span {
          font-size: 40px;
          line-height: rem(24px);
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: $dark-brown-color;
          font-family: $font-secondary;

          @include respond-to("medium and down") {
            font-size: 32px;
            line-height:24px;
          }
        }
      }
      .arrow{
        position: absolute;
        display: grid;
        left: rem(48px);
        right: rem(38px);
        bottom: rem(11px);
        margin: 0 auto;
        transform: translate(rem(0px));
        opacity: 0;
      }
      &__picture:hover{
        .arrow{
          display: grid;
          transform: translate(rem(50px));
          transition: all 100ms ease-in-out;
          opacity: 1;
        }
      }

      &__picture{
        display: grid;
        border-radius: rem(16px);
        background-size: cover;


        @include respond-to("medium and up") {
          height: rem(480px);
        }
        @include respond-to("small and down") {
          height: rem(240px);
          background-position: 0;
        }
        &.ground {
          background-image: linear-gradient(360deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0)35.62%), url(../assets/picture-earth-desktop.jpg);
          @include respond-to("medium and down") {
            background-image: linear-gradient(360deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0)35.62%), url(../assets/picture-earth-mobile.jpg);
          }
        }
        &.air {
          background-image: linear-gradient(360deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0)35.62%), url(../assets/picture-air-desktop-mobile.jpg);
        }
        &.water {
          background-image: linear-gradient(360deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0)35.62%), url(../assets/picture-water-desktop-mobile.jpg);
        }
        &.lodging {
          background-image: linear-gradient(360deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0)35.62%), url(../assets/picture-lodging-desktop.jpg);
          background-position: center;
        }
        &.other-services {
          background-image: linear-gradient(360deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0)35.62%), url(../assets/picture-other-services-desktop.jpg);
          background-position: center;
        }

        a {
          position: absolute;
          left: rem(48px);
          right: rem(48px);
          bottom: rem(48px);
          margin: 0 auto;
          width: calc(100% - (48px * 2));
          @include respond-to("small and down") {
            left: rem(16px);
            right: rem(16px);
            bottom: rem(16px);
            width: calc(100% - (16px * 2));
          }
        }
      }
      p {
        font-weight: 500;
        line-height: rem(24px);
        text-align: center;
        letter-spacing: 0.05em;
        color: $dark-brown-color;

        margin-bottom: rem(32px);

        @include respond-to("small and down") {
          margin-bottom: rem(23px);
        }
      }
    }

    &__more-activities {
      display: grid;
      margin-top: rem(50px);
      margin-bottom: rem(115px);
      @media (max-width: 1386px) {
        .other-services {
          img {
            width: 48px;
          }
          span {
            width: 210px;
            height: auto;
            font-size: 40px;
            letter-spacing: 0.05em;
            line-height: 40px;
          }
        }
      }
      @include respond-to("large and up") {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: rem(48px);
        padding: rem(0px) rem(48px);
        &__card {
          width: 416px;
          margin: 0 auto;
        }

        .other-services {
          img {
            width: 35px;
          }
        }

        .special-image {
          img {
            width: 20px;
          }
        }
      }
      @include respond-to("medium and down") {
        .other-services {
          img {
            width: 32px;
          }
          span {
            width: auto;
            height: auto;
            font-size: 32px;
            letter-spacing: 0.05em;
            line-height: 24px;
          }
        }
      }

      .special-image {
          img {
            width: 20px;
          }
        }
        
      &__card {
        position: relative;
        display: grid;

      }

    }
    &__activity {
      display: grid;
      margin-top: rem(50px);

      @include respond-to("large and up") {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: rem(48px);
        padding: rem(0px) rem(48px);
      }
      &__card {
        position: relative;
        display: grid;
        grid-template-rows: auto auto 1fr;
      }
    }

    .other-services {
      margin-left: 0;
    }
    .lodging {
      margin-right: 0;
    }

    .more-activities {
      @include respond-to("large and up") {
        grid-template-columns: 1fr 1fr ;
        grid-column-gap: rem(48px);
        padding: 0 280px rem(48px) 280px;
        margin: 0;
      }
    }
  }
</style>

<template>
  <div
    class="mobile-drawer"
    :class="{ open: mobileDrawerVisible }"
    v-if="!($route.name === 'contact-payment')"
  >
    <div
      class="mobile-drawer__activator"
      v-show="!mobileDrawerVisible"
      :class="{ hide: noButton }"
    >
      <a href="" @click.prevent="openDrawer">
        <img src="@/assets/components/mobile-button.svg" alt="" />
      </a>
      <a href="" class="gtm-quoter button" @click.prevent="openDrawer">{{
        $t("getPrices")
      }}</a>
    </div>

    <div
      class="mobile-drawer__content"
      v-if="mobileDrawerVisible"
      :class="{ disabled: inRemoteActivity }"
    >
      <div class="mobile-drawer__content__close">
        <a
          href=""
          @click.prevent="changeStep('1')"
          class="go-back-button"
          v-show="currentStep == '2'"
          >{{ $t("activities.back") }}</a
        >
        <a href="" @click.prevent="closeDrawer" class="close-button"
          ><img src="@/assets/deletesign.svg"
        /></a>
      </div>

      <div
        class="mobile-drawer__content__first-step"
        v-show="currentStep == '1'"
      >
        <div class="mobile-drawer__stays">
          <stay-options
            @staySelected="staySelected"
            @setCabins="setCabins"
            @setTipis="setTipis"
            @setLakeVillages="setLakeVillages"
            :stayType="stayType"
            :analyticsCabin="analyticsCabin"
          ></stay-options>
        </div>
      </div>

      <div
        class="mobile-drawer__content__second-step"
        v-show="currentStep == '2'"
      >
        <div class="mobile-drawer__header">
          <div class="title">
            <img :src="getStayImage(stayOptionSelected, 'iconSrc')" alt="" />
            <p>{{ $t(getStayImage(stayOptionSelected, "name")) }}</p>
          </div>
          <div
            class="selectors"
            v-show="
              stayOptionSelected == 'CABIN' ||
                stayOptionSelected == 'TIPI' ||
                stayOptionSelected == 'LAKE_VILLAGE'
            "
          >
            <span
              @click.prevent="
                stayOptionSelected == 'CABIN'
                  ? openChooseCabinModal()
                  : stayOptionSelected == 'TIPI'
                  ? openChooseTipiModal()
                  : openChooseLakeVillageModal()
              "
              v-show="step == 5"
            >
              {{
                stayOptionSelected == "CABIN"
                  ? $t("modal.chooseReservation.pickYourCabin")
                  : stayOptionSelected == "TIPI"
                  ? $t("modal.chooseTepee.pickYourTepee")
                  : $t("modal.chooseVillage.pickYourVillage")
              }}
            </span>

            <counter
              :ref="counterRef"
              :on-change="onChangeByStay"
              :max-allowed="maxAllowedByStay"
              :min="stayLimitByPeople"
            >
            </counter>
          </div>
          <div class="limit-space">
            <div
              v-show="
                    quote &&
                    quote.tipiNumbers &&
                    quote.tipiNumbers.length > 0 &&
                    quote.tipiNumbers.some(tipi => tipi.number == 6)
                    "
              class="limit-space__reservation"
            >
              <img src="@/assets/date-picker/unavailable.svg" alt="" />
              <span>{{ $t("modal.chooseTepee.warningAlt") }}</span>
            </div>
          </div>
          <div
            class="unavailable"
            v-if="fullCabins || fullTipis || fullLakeVillages"
          >
            <div
              class="unavailable__reservation"
              v-if="
                fullCabins && quote && quote.id && quote.products.cabins > 0
              "
            >
              <img src="@/assets/date-picker/unavailable.svg" alt="" />
              <span>{{ $t("noCabins") }}</span>
            </div>
            <div
              class="unavailable__reservation"
              v-if="fullTipis && quote && quote.id && quote.products.tipis > 0"
            >
              <img src="@/assets/date-picker/unavailable.svg" alt="" />
              <span>{{ $t("noTipis") }}</span>
            </div>
            <div
              class="unavailable__reservation"
              v-if="
                fullLakeVillages &&
                  quote &&
                  quote.id &&
                  quote.products.lakeVillages > 0
              "
            >
              <img src="@/assets/date-picker/unavailable.svg" alt="" />
              <span>{{ $t("noLakeVillages") }}</span>
            </div>
          </div>
        </div>

        <div
          class="mobile-drawer__dates"
          v-show="!openActivities"
          :class="{
            'hide-promotion':
              quote && quote.visit && quote.visit.numberOfPersons > 0,
          }"
        >
          <h4 v-if="quote.visit.stayType == 'DAY_PASS'">
            {{ $t("whichDayAreYouStaying") }}
          </h4>
          <h4 v-else>{{ $t("whichDaysAreYouStaying") }}</h4>
          <div class="dates-selectors">
            <visit-dates
              :step="step"
              ref="dates"
              :pricesPerDay="pricesPerDay"
              :unavailable-lodging-dates="unavailableLodgingDates"
              @setDeparture="setDeparture"
              @openModal="changeDatesOpen"
            ></visit-dates>
          </div>
        </div>
        <div class="mobile-drawer__nop" v-show="!openActivities">
          <h4>{{ $t("howManyPeople") }}</h4>
          <visit-nop class="mobile" :step="step"></visit-nop>
        </div>
        <div class="mobile-drawer__arrival" v-show="!openActivities">
          <h4>{{ $t("arrivalTime") }}</h4>
          <visit-arrival class="mobile" :step="step"></visit-arrival>
        </div>
        <visit-promotion
          v-if="showVisitPromotion"
          :promotion="promotion"
          class="drawer"
        ></visit-promotion>
        <div class="mobile-drawer__resume">
          <div class="unavailable">
            <div class="unavailable__content" v-if="capacityFull">
              <img
                class="unavailable__content__info"
                src="@/assets/info.svg"
                alt=""
              />
              <div class="unavailable__content__message">
                {{ $t("capacityFull") }}
              </div>
            </div>
            <div class="unavailable__content" v-if="yellowDateSelected">
              <img
                class="unavailable__content__info"
                src="@/assets/info.svg"
                alt=""
              />
              <div class="unavailable__content__message">
                {{ $t("advertYellowDates") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-drawer__actions" :class="{ hide: noButton }">
        <div
          class="mobile-drawer__total"
          v-if="quote"
          v-show="currentStep == '2'"
        >
          <div class="mobile-drawer__total-label">{{ $t("total") }}</div>
          <h2 class="mobile-drawer__total-amount" v-if="showTotalAmount">
            {{ quote.totalAmount | currency }} <span>MXN</span>
          </h2>
          <h2 class="mobile-drawer__total-amount" v-else>$ -</h2>
        </div>
        <unavailable-reservation v-if="!capacityFull"></unavailable-reservation>
        <div class="unavailable" v-if="capacityFull">
          {{ $t("capacityFull") }}
        </div>
        <a
          href=""
          class="gtm-step3 btn btn--primary"
          :class="{ disabled: disabledPayButton || disabled }"
          v-if="currentStep == '2'"
          v-show="!inCart"
          @click.prevent="goToShoppingCart()"
        >
          {{ $t("pay") }}
        </a>
        <a
          href=""
          class="gtm-step3 btn btn--primary"
          :class="{ disabled: disabledPayButton || disabled }"
          v-show="inCart && currentStep == '2'"
          @click.prevent="closeDrawer"
        >
          {{ $t("pay") }}
        </a>
      </div>
    </div>
    <changes-date-cancel-promo
      class="modal"
      ref="changesDateCancelPromo"
      :promotion="promotion"
      @confirm="confirmUpdate"
    ></changes-date-cancel-promo>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VisitDates from "@/components/visit-drawer/VisitDates";
import VisitNop from "@/components/visit-drawer/VisitNop";
import VisitArrival from "@/components/visit-drawer/VisitArrival";
import VisitPromotion from "@/components/visit-drawer/VisitPromotion";
import UnavailableReservation from "@/components/UnavailableReservation";
import ChangesDateCancelPromo from "@/components/modal/ChangesDateCancelPromo";
import StayOptions from "@/components/StayOptions.vue";
import Counter from "@/components/visit-drawer/Counter";
import moment from "moment";
import { LODGING } from '@/utils/catalog';

const PRICES_BY_COLOR = ["BLACK", "BLUE", "RED", "YELLOW"];

const COUNTER_REF = {
  CABIN: "cabinCounter",
  TIPI: "tipiCounter",
  LAKE_VILLAGE: "lakeVillageCounter",
};

export default {
  name: "mobile-drawer",
  components: {
    VisitDates,
    VisitNop,
    VisitArrival,
    VisitPromotion,
    UnavailableReservation,
    ChangesDateCancelPromo,
    StayOptions,
    Counter,
  },
  props: ["noButton", "inCart"],
  computed: {
    ...mapState([
      "mobileDrawerVisible",
      "quote",
      "isUnavailableCabins",
      "isUnavailableTipis",
      "isUnavailableLakeVillage",
      "inRemoteActivity",
      "yellowDateSelected",
      "fullCabins",
      "fullTipis",
      "fullLakeVillages",
    ]),
    counterRef() {
      return COUNTER_REF[this.stayOptionSelected];
    },
    onChangeByStay() {
      return this.stayOptionSelected === "CABIN"
        ? this.setCabins
        : this.stayOptionSelected === "TIPI"
        ? this.setTipis
        : this.setLakeVillages;
    },
    maxAllowedByStay() {
      return this.stayOptionSelected === "CABIN"
        ? this.maxCabins
        : this.stayOptionSelected === "TIPI"
        ? this.maxTipis
        : this.maxLakeVillages;
    },
    reservationsWithOutPersons() {
      if (!this.quote) {
        return;
      }
      return (
        this.quote &&
        this.quote.products &&
        this.quote.products.cabins +
          this.quote.products.tipis +
          this.quote.products.lakeVillages >
          0 &&
        this.quote.visit.numberOfPersons == 0
      );
    },
    promotion() {
      return this.quote && this.quote.promotion;
    },
    noCanopyTourHorseBackRidingAvaiable() {
      if (this.quote) {
        let itemKeys = this.quote.items
          .filter((i) => i.quantity > 0)
          .map((i) => i.product.key);
        return (
          itemKeys.indexOf("horseback_riding_pass") > -1 ||
          itemKeys.indexOf("canopy_pass") > -1
        );
      }
      return false;
    },
    unavailableCabins() {
      return this.$store.getters.unavailableCabins;
    },
    unavailableTipis() {
      return this.$store.getters.unavailableTipis;
    },
    unavailableLakeVillages() {
      return this.$store.getters.unavailableLakeVillages;
    },
    unavailableReservations() {
      return (
        (this.unavailableCabins && this.unavailableCabins.length > 0) ||
        (this.unavailableTipis && this.unavailableTipis.length > 0) ||
        (this.unavailableLakeVillages &&
          this.unavailableLakeVillages.length > 0)
      );
    },
    capacityFull() {
      if (!this.quote) {
        return false;
      }

      if (
        (this.quote.cabinNumbers && this.quote.cabinNumbers.length > 0) ||
        (this.quote.tipiNumbers && this.quote.tipiNumbers.length > 0) ||
        (this.quote.lakeVillageNumbers &&
          this.quote.lakeVillageNumbers.length > 0)
      ) {
        return false;
      }

      const haveDates =
        this.quote &&
        this.quote.visit &&
        this.quote.visit.arrivalDate &&
        this.quote.visit.departureDate &&
        this.quote.visit.arrivalTime;
      return haveDates
        ? this.quote.visit.arrivalTime === "DAY"
          ? this.$store.getters.capacityFullDay
          : this.$store.getters.capacityFullNight
        : false;
    },
    maxDate() {
      let mountsAfter = process.env.VUE_APP_10;
      return moment()
        .endOf("year")
        .add(mountsAfter, "M")
        .format("YYYY-MM-DD");
    },
    step() {
      let step = 1;
      let visit = this.quote && this.quote.visit;

      if (visit) {
        if (visit.stayType) {
          step = 2;
        }
        if (
          visit.arrivalDate &&
          (visit.stayType == "DAY_PASS" || this.departureDateSet) &&
          visit.arrivalTime
        ) {
          step = 3;
        }
        if (
          (visit.arrivalDate &&
            (visit.stayType == "DAY_PASS" || this.departureDateSet)) ||
          visit.numberOfPersons
        ) {
          step = 4;
        }
        if (
          visit.arrivalDate &&
          (visit.stayType == "DAY_PASS" || this.departureDateSet) &&
          visit.arrivalTime &&
          visit.numberOfPersons
        ) {
          step = 5;
        }
      }
      return step;
    },
    visitActivityReservations() {
      return this.quote && this.quote.visitActivityReservations
        ? this.quote.visitActivityReservations
        : [];
    },
    showTotalAmount() {
      return (
        this.quote &&
        this.quote.visit &&
        this.quote.totalAmount > 0 &&
        this.quote.visit.departureDate
      );
    },
    disabledPayButton() {
      return (
        this.unavailableReservations ||
        this.capacityFull ||
        this.isUnavailableCabins ||
        this.isUnavailableTipis ||
        this.isUnavailableLakeVillage ||
        this.remoteActivity ||
        this.reservationsWithOutPersons ||
        !this.quote ||
        !this.quote.visit ||
        !this.quote.visit.departureDate ||
        this.quote.totalAmount == 0 ||
        !this.quote.visit.numberOfPersons ||
        !this.quote.visit.arrivalTime
      );
    },
    remoteActivity() {
      return this.inRemoteActivity;
    },
    visitStayType() {
      return this.quote && this.quote.visit ? this.quote.visit.stayType : null;
    },
    isLodge() {
      if (!this.quote) {
        return false;
      }

      return ["CABIN", "TIPI", "LAKE_VILLAGE"].includes(this.visitStayType);
    },
    stayLimitByPeople() {
      if (!this.isLodge) {
        return 1;
      }

      const minStaysQuantity =
        this.quote.visit.numberOfPersons / this.maxLodgeCapacity;
      const minStaysQuantityInt = parseInt(Math.ceil(minStaysQuantity));

      return Math.max(1, minStaysQuantityInt);
    },
    showVisitPromotion() {
      if (this.isLodge) {
        const stayings = {
          CABIN: "cabins",
          TIPI: "tipis",
          LAKE_VILLAGE: "lakeVillages",
        };
        return (
          this.needMoreRentals(stayings[this.quote.visitType]) || this.promotion
        );
      }

      return (
        (this.quote.visit && this.quote.visit.numberOfPersons > 0) ||
        this.promotion
      );
    },
  },
  watch: {
    disabledPayButton() {
      this.updateDisabled();
    },
    "quote.visit.stayType": function() {
      this.stayOptionSelected = this.quote.visit.stayType;
    },
    "quote.visit.departureDate": function() {
      this.initPricesLoading();
    },
    "quote.visit.arrivalDate": function() {
      this.initPricesLoading();
    },
    isSelected() {
      this.isSelected;
    },
    "quote.products.cabins": function() {
      this.updateCabinsNumber();
    },
    "quote.products.tipis": function() {
      this.updateTipisNumber();
    },
    "quote.products.lakeVillages": function() {
      this.updateLakeVillageNumber();
    },
    "$store.state.visitDrawerVisible": async function() {
      await this.updateUnavailableLodgingDates();
    },
  },
  data() {
    return {
      prices: {
        dayPass: null,
        campingPass: null,
        canopyPass: null,
        cabin: null,
        tipi: null,
      },
      timeout: null,
      pricesPerDay: null,
      datesForApply: null,
      openActivities: false,
      stayType: [
        {
          id: "DAY_PASS",
          name: "oneDayPass",
          iconSrc: require("../assets/products/day-pass--icon--gray.svg"),
          hour: "7:30am - 6:00pm",
          imgSrc: require("@/assets/compressed/day-pass.webp"),
        },
        {
          id: "CAMPING_PASS",
          name: "camping",
          iconSrc: require("../assets/products/camping--icon--gray.svg"),
          hour: "6:00pm - 6:00pm",
          imgSrc: require("@/assets/compressed/camping.webp"),
        },
        {
          id: "CABIN",
          name: "ecotouristCabins",
          iconSrc: require("../assets/products/cabins--icon--gray.svg"),
          hour: "3:00pm - 12:00pm",
          imgSrc: require("@/assets/compressed/eco-cabins.webp"),
        },
        {
          id: "TIPI",
          name: "teepee",
          iconSrc: require("../assets/products/tipee--icon--gray.svg"),
          hour: "3:00pm - 12:00pm",
          imgSrc: require("@/assets/compressed/tipee.webp"),
        },
        {
          id: "LAKE_VILLAGE",
          name: "lakeVillage",
          iconSrc: require("../assets/products/lake--icon--gray.svg"),
          hour: "3:00pm - 12:00pm",
          imgSrc: require("@/assets/compressed/lake-cabin.webp"),
        },
      ],
      currentStep: "1",
      isSelected: false,
      stayOptionSelected: null,
      maxCabins: LODGING.CABIN,
      maxTipis: LODGING.TIPI,
      maxLakeVillages: LODGING.LAKE_VILLAGE,
      analyticsCabin: "",
      departureDateSet: false,
      unavailableLodgingDates: [],
      maxLodgeCapacity: 6,
      disabled: true,
    };
  },
  mounted() {
    this.updateCabinsNumber();
    this.updateTipisNumber();
    this.updateLakeVillageNumber();
    this.getPricesPerDay();

    if (!this.quote) {
      this.$store.dispatch("loadQuote");
      this.initPricesLoading();
      this.getPricesPerDay();
    }

    if (this.quote) {
      this.staySelected(this.quote.visitType);

      if (this.isOnShoppingCart) {
        this.changeStep("2");
      }
    }

    this.setDeparture();
  },
  methods: {
    updateDisabled() {
      setTimeout(() => {
        this.disabled = this.disabledPayButton;
      }, 400);
    },
    confirmUpdate() {
      this.updateVisitDates();
      this.$refs.changesDateCancelPromo.closeModal();
    },
    changeDatesOpen(data) {
      this.datesForApply = data;
      this.$refs.changesDateCancelPromo.openModal();
    },
    async updateVisitDates() {
      await this.$store.dispatch("updateVisit", this.datesForApply);
    },
    async openDrawer() {
      await this.$store.dispatch("showMobileDrawer");
      window.scrollTo(0, 0);
      this.initPricesLoading();
      this.getPricesPerDay();
      this.onViewItemListEvent();
    },
    closeDrawer() {
      this.$store.dispatch("hideMobileDrawer");
      this.$store.dispatch("hideVisitDrawer");
      this.onGenericEvent(`cerrar cotizador`, {
        event_category: "cotizador",
        event_label: `chevron`,
      });
    },
    initPrices() {
      this.$set(this, "prices", {
        dayPass: null,
        campingPass: null,
        canopyPass: null,
        cabin: null,
        tipi: null,
      });
    },
    initPricesLoading() {
      if (
        this.$refs &&
        this.$refs.dates &&
        this.$refs.dates.startDateUTF &&
        this.$refs.dates.endDateUTF
      ) {
        this.loadPrices(this.$refs.dates.startDateUTF);
        clearTimeout(this.timeout);
      } else {
        if (!this.timeout) {
          this.initEmptystate();
        }
        this.timeout = setTimeout(this.initPricesLoading, 200);
      }
    },
    async loadPrices(startDate) {
      try {
        this.initPrices();
        let prices = await this.$store.dispatch("loadPricesByDate", startDate);
        this.$set(this, "prices", prices);
      } catch (e) {
        return e;
      }
    },
    goToShoppingCart() {
      //TODO: WHY ONLY CABINS!?
      if (
        this.quote &&
        this.quote.totalAmount != 0 &&
        !this.isUnavailableCabins &&
        !this.disabledPayButton
      ) {
        this.onAddToCartEvent();
        this.analyticsAddToCart();
        this.onSuccessfulReservationEvent();
        this.$router.push({ name: "shopping-cart" });
      }
    },
    book() {
      //TODO: WHY ONLY CABINS !?
      if (
        this.quote &&
        this.quote.totalAmount != 0 &&
        !this.isUnavailableCabins
      ) {
        this.analyticsBook();
        this.$store.dispatch("hideMobileDrawer");
        this.$store.dispatch("hideVisitDrawer");
        this.$router.push({ name: "contact-payment" });
      }
    },
    analyticsAddToCart() {
      this.onGenericEvent(`agregar al carrito`, {
        event_category: "cotizador",
        event_label: `cta - agregar al carrito`,
      });
    },
    analyticsBook() {
      this.onGenericEvent(`reservar`, {
        event_category: "cotizador",
        event_label: `cta - reservar`,
      });
    },
    async initEmptystate() {
      try {
        let year = new Date().getFullYear();
        let pricesPerYear = await this.$store.dispatch(
          "loadPricesByYear",
          year
        );
        let currentYear = new Date().getFullYear();
        let firstDayOfYear = `${currentYear}-01-01`;
        let prices = await this.$store.dispatch("loadPrices", {
          start: firstDayOfYear,
          end: this.maxDate,
        });
        let pricesByColor = this.groupBy(prices, "color");
        let hasColor = PRICES_BY_COLOR.find((color) =>
          pricesByColor.hasOwnProperty(color)
        );
        if (!hasColor) {
          return;
        }
        this.assignPrices(pricesByColor[hasColor], pricesPerYear);
      } catch (error) {
        return error;
      }
    },
    assignPrices(datePrice, pricesPerYear) {
      this.prices = {
        dayPassPrice: datePrice[0].datePrices.dayPassPrice,
        campingPassPrice:
          datePrice[0].datePrices.campingPassPrice +
          datePrice[0].datePrices.dayPassPrice,
        canopyPrice: datePrice[0].datePrices.canopyPrice,
        cabinPrice: datePrice[0].datePrices.cabinPrice,
        tipiPrice: datePrice[0].datePrices.tipiPrice,
        horsebackRidingPrice: pricesPerYear.horsebackRidingPrice,
      };
    },
    groupBy(prices, property) {
      return prices.reduce((group, currentValue) => {
        let item = currentValue.datePrices[property];
        group[item] = group[item] || [];
        group[item].push(currentValue);

        return group;
      }, {});
    },
    async getPricesPerDay() {
      try {
        let currentYear = new Date().getFullYear();
        let firstDayOfYear = `${currentYear}-01-01`;
        let prices = await this.$store.dispatch("loadPrices", {
          start: firstDayOfYear,
          end: this.maxDate,
        });
        this.pricesPerDay = prices;
      } catch (error) {
        return error;
      }
    },
    goToActivities() {
      this.openActivities = !this.openActivities;
    },
    changeStep(value) {
      this.currentStep = value;

      if (this.currentStep == "1") {
        this.onViewItemListEvent();
      } else if (this.currentStep == "2") {
        this.onViewItemEvent();
      }
    },
    async staySelected(value) {
      if (value) {
        this.isSelected = true;
        await this.updateUnavailableLodgingDates(value);
        this.stayOptionSelected = value;
        this.onSelectItemEvent(value);
        this.currentStep = "2";
      }
    },
    async getUnavailableLodgingDates(stay) {
      let stayType = stay;
      if (!stay) {
        let visit = this.quote && this.quote.visit;
        stayType = visit.stayType;
      }

      const isLodging = ["CABIN", "TIPI", "LAKE_VILLAGE"].includes(stayType);
      if (!isLodging) {
        return [];
      }

      let quantity = this.getQuantityByStay(stayType);
      let command = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: this.maxDate,
        quantity: quantity,
        stayType: stayType,
      };
      return await this.$store.dispatch("getUnavailableLodging", command);
    },
    getQuantityByStay(stayType) {
      let quantity = 0;
      const products = this.quote.products;
      switch (stayType) {
        case "CABIN":
          quantity = products.cabins;
          break;
        case "TIPI":
          quantity = products.tipis;
          break;
        case "LAKE_VILLAGE":
          quantity = products.lakeVillages;
          break;
        default:
          break;
      }
      return quantity;
    },
    getStayImage(option, imageType) {
      if (option) {
        if (imageType == "imgSrc") {
          return this.stayType.filter((stay) => stay.id == option)[0].imgSrc;
        } else if (imageType == "iconSrc") {
          return this.stayType.filter((stay) => stay.id == option)[0].iconSrc;
        } else {
          return this.stayType.filter((stay) => stay.id == option)[0].name;
        }
      }
    },
    async setCabins(newNumberOfCabins, options) {
      if (newNumberOfCabins > this.maxCabins) {
        return;
      }

      if (options) {
        if (options.action == "sub") this.analyticsCabin = "Subtract";
        else if (options.action == "add") this.analyticsCabin = "Add";
        else if (options.action == "manual") this.analyticsCabin = "Manual";
      }

      const products = this.quote.products;
      products.cabins = newNumberOfCabins;
      products.tipis = 0;
      products.lakeVillages = 0;

      await this.updateProducts(products);
    },
    async setTipis(newNumberOfTipis) {
      const products = this.quote.products;
      products.tipis = newNumberOfTipis;
      products.cabins = 0;
      products.lakeVillages = 0;

      await this.updateProducts(products);
    },
    async setLakeVillages(newNumberOfLakeVillages) {
      const products = this.quote.products;
      products.lakeVillages = newNumberOfLakeVillages;
      products.cabins = 0;
      products.tipis = 0;

      await this.updateProducts(products);
    },
    async updateProducts(products) {
      await this.$store.dispatch("updateProducts", products);
      await this.updateUnavailableLodgingDates();
    },
    async updateUnavailableLodgingDates(stay = null) {
      this.unavailableLodgingDates = await this.getUnavailableLodgingDates(
        stay
      );
    },
    updateCabinsNumber() {
      setTimeout(() => {
        this.$refs.cabinCounter &&
          this.$refs.cabinCounter.setQuantity(this.quote.products.cabins);
      }, 300);
    },
    updateTipisNumber() {
      setTimeout(() => {
        this.$refs.tipiCounter &&
          this.$refs.tipiCounter.setQuantity(this.quote.products.tipis);
      }, 300);
    },
    updateLakeVillageNumber() {
      setTimeout(() => {
        this.$refs.lakeVillageCounter &&
          this.$refs.lakeVillageCounter.setQuantity(
            this.quote.products.lakeVillages
          );
      }, 300);
    },
    setDeparture() {
      this.departureDateSet = localStorage.getItem("DEPARTURE_DATE") != null;
    },
    openChooseTipiModal() {
      this.$store.dispatch("updateChooseTipiVisible", true);
    },
    openChooseCabinModal() {
      this.$store.dispatch("updateChooseCabinVisible", true);
    },
    openChooseLakeVillageModal() {
      this.$store.dispatch("updateChooseLakeVillageVisible", true);
    },
    onAddToCartEvent() {
      this.$root.$emit("onAddToCartEvent", this.quote.productItems);
    },
    onSuccessfulReservationEvent() {
      this.$root.$emit("onSuccessfulReservationEvent", this.quote);
    },
    onViewItemListEvent() {
      this.$root.$emit("onViewItemListEvent", this.stayType);
    },
    onSelectItemEvent(item) {
      if (this.mobileDrawerVisible) {
        this.$root.$emit("onSelectItemEvent", item);
      }
    },
    onViewItemEvent() {
      this.$root.$emit("onViewItemEvent", this.stayOptionSelected);
    },
    onGenericEvent(event, command) {
      this.$root.$emit("onGenericEvent", event, command);
    },
    needMoreRentals(stayType) {
      let rentals = this.quote.products[stayType];
      if (rentals && rentals > 0) {
        let numberOfPersons = this.quote.visit.numberOfPersons;
        let personsPerRentals = rentals * this.maxLodgeCapacity;
        return numberOfPersons > personsPerRentals;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-drawer {
  display: grid;
  overflow: auto;
  @include respond-to("large and up") {
    display: none;
  }
  width: 100%;
  background-color: white;
  padding: 1rem;
  z-index: 10000;
  transition: height 0.2s;
  position: sticky;

  &__activator {
    text-align: center;
    display: grid;
    a,
    a:focus,
    a:active {
      outline: 0;
      box-shadow: none;
    }
    img {
      margin-bottom: rem(24px);
    }
  }
  &.open {
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 9000000000 !important;
    padding-bottom: 0;
  }
  &:not(.open) {
    position: sticky;
    bottom: 0;
  }
  &__header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    row-gap: 8px;
    margin-top: 16px;
    .title {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      gap: 9.05px;
      img {
        width: 24px;
        height: 24px;
        margin: 5px 0;
      }
      p {
        font-family: "Chau Philomene One";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $light-gray;
        width: 100%;
      }
    }
    .selectors {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;
      align-items: center;
      .counter::v-deep {
        height: 30px;
        grid-template-columns: repeat(3, 1fr);
        width: 136px;
        margin-left: auto;
        margin-right: 0;
        .counter__section {
          height: 100%;
          width: 30px;
        }
        img {
          height: 14px;
          width: 14px;
          margin: 9px;
        }
        input {
          height: 100%;
          background: transparent;
        }
      }
      span {
        margin-right: auto;
        margin-left: 0;
        color: #0075ff;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .unavailable,
    .limit-space {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      height: auto;
      &__reservation {
        display: grid;
        grid-template-columns: 24px 1fr;
        gap: 8px;
        height: 100%;
        letter-spacing: 0.05em;
        line-height: 24px;
        span {
          color: #ff3030;
        }
        img {
          height: 24px;
          width: 100%;
        }
      }
    }
  }
  &__content {
    height: auto;
    &.disabled {
      pointer-events: none;
    }
    &__close {
      display: flex;
      flex-direction: row;
      text-align: right;
      margin: -16px;
      height: 50px;
      .go-back-button {
        color: #0075ff;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 16px auto 16px 16px;
      }
      .close-button {
        margin: 16px 16px 16px auto;
      }
    }
    &__second-step {
      height: calc(100% - 176px);
    }
  }
  &__stays::v-deep {
    margin-top: 16px;
    height: calc(100dvh - 176px - 16px);
    .visit-stays {
      height: 100%;
    }
    .visit-stays__header {
      height: 22px;
      margin-bottom: 24px;
    }
    .visit-stays__content {
      display: grid;
      gap: 10px;
      height: calc(100% - 46px);
    }
    p {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      line-height: 22px;
    }
    .visit-stays__content__cards {
      width: auto;
      height: 100%;
      display: grid;
      padding-bottom: 0;
      &__image {
        height: 100%;
        width: 24%;
        align-items: inherit;
        border-radius: 8px 0 0 8px;
      }
      &__info {
        margin-left: 24%;
        padding-left: 16px;
        height: calc(100% - 32px);
        align-content: center;
        width: auto;
        .card-header {
          grid-template-columns: 24px 1fr;
          .stay-name {
            width: auto;
          }
        }
        .card-description {
          display: flex;
          span {
            align-self: flex-start;
          }
        }
      }
      &.selected {
        outline: 4px solid #ff9900;
        border-radius: 8px;
      }
    }
  }
  &__option {
    .visit-stays__option {
      background-color: red;
      align-self: start !important;
    }
  }
  &__dates,
  &__button,
  &__arrival,
  &__nop,
  &__header {
    font-size: rem(16px);
    line-height: rem(22px);
    letter-spacing: 0.05em;
    margin-top: rem(16px);
    padding-bottom: rem(16px);
    border-bottom: 1px dashed #786f66;
    h4 {
      color: rgba(#000, 0.6);
      margin-bottom: rem(16px);
    }
  }
  &__arrival {
    margin-bottom: rem(16px);
  }
  &__dates {
    padding-bottom: rem(20px);
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr;
    @include respond-to("medium and down") {
      .visit-dates::v-deep {
        display: grid;
        grid-template-columns: auto auto;
        gap: 16px;
        .visit-dates__arrival,
        .visit-dates__departure {
          .visit-dates__departure__date-picker {
            &.open {
              position: absolute;
              right: 0;
              left: auto;
            }
          }
          .calendar-drawer {
            width: 100%;
            span {
              font-size: 16px;
            }
            .vc-weeks {
              row-gap: 10px !important;
            }
            .vc-weekday {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              color: #786f66;
            }
          }
        }
      }
    }
  }
  &__arrival {
    .visit-arrival-time::v-deep {
      .visit-arrival-time__time {
        display: grid;
        grid-template-columns: auto auto;
        gap: 16px;
        a {
          border: 0.5px solid #1e0f00;
        }
        .selected {
          border: 1px solid #ff9900;
        }
      }
    }
  }
  .visit-promotion::v-deep {
    margin-left: 0;
    margin-right: 0;
    grid-template-columns: none;
    margin-bottom: 16px;
  }
  &__button {
    border-bottom: none;
    h4 {
      margin-bottom: 1rem;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &__link {
    color: $blue-great-color;
    font-size: rem(16px);
    font-weight: 500;
    text-decoration: none;
  }
  &__resume {
    .unavailable {
      grid-area: unavailable;
      &__content {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 4px;
        background-color: #ffdede;
        border-radius: rem(8px);
        padding: rem(8px) rem(10px);
        margin-bottom: rem(12px);

        &__message {
          max-width: rem(800px);
          font-weight: 500;
          font-size: rem(16px);
          letter-spacing: 0.05em;
          line-height: rem(24px);
          color: $red-color2;
        }
        &__info {
          padding: 2.4px;
        }
      }
    }
  }
  &__actions {
    z-index: 5;
    box-shadow: 0 rem(-7px) rem(24px) rgba(#868686, 0.13);
    border-radius: rem(16px) rem(16px) 0 0;
    padding: rem(16px);
    background-color: #fff;
    width: calc(100% + 32px);
    margin-left: rem(-16px);
    margin-top: rem(5px);
    bottom: rem(-1px);
    position: sticky;
    display: grid;
    grid-row-gap: 1rem;
    .unavailable {
      line-height: 24px;
      color: $red-color2;
    }
  }
  &__total {
    margin-top: 0.5rem;
    color: $dark-brown-color;
    display: grid;
    grid-column-gap: 0.5rem;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    height: rem(33px);
  }
  &__total-label {
    font-weight: 500;
    text-transform: capitalize;
    align-self: end;
  }
  &__total-amount {
    font-weight: normal;
    font-size: 2rem;
    line-height: 1.5rem;
    text-align: right;
    span {
      font-size: 16px;
      width: 40px;
    }
  }
  &__unavaible {
    display: grid;
    grid-row-gap: 1rem;
    &__reservation {
      letter-spacing: 0.05em;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      display: grid;
      grid-template-columns: 1.5rem 1fr;
      grid-column-gap: 0.5rem;
      align-items: start;
      text-align: left;
      span {
        color: #ff3030;
      }
    }
  }
  @include respond-to("small and down") {
    &__unavaible {
      padding-top: 1.7rem;
      letter-spacing: 0.046em;
    }
  }
}
.hide {
  display: none;
  background-color: red;
}

.limit-space {
  display: block;
  gap: 8px;
  height: 100%;
  letter-spacing: 0.05em;
  line-height: 24px;
  span {
    color: #ff3030;
  }
  img {
    height: 24px;
    width: 100%;
  }
}
</style>
